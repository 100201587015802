var osFreeGames = new OnScreen({
    tolerance: -600
});
// Do something when an element enters the viewport
osFreeGames.on('enter', '#jsFreeGamesWidget', function myCallback() {
    $('.filter-clear').trigger('click');
    osFreeGames.destroy();
});

$(document).ready(function () {
    // Free games
    var large = 1024;
    var windowSize = $(window).width();

    var freeGamesSelector = $('#jsFreeGamesWidget');
    // menu click
    freeGamesSelector.on('click', '.categories__item', function () {
        freeGamesSelector.find('.categories__item').not($(this)).removeClass('categories__item--active');
        $(this).addClass('categories__item--active');
        freeGamesSelector.find('.jsFreeGamesTitle').text($(this).find('span').text());
        $(this).parent().removeClass('categories__menu--open');
        $('#sort_game').val($(this).data('val')).trigger('change');
    });
    freeGamesSelector.on('click', '.jsFreeGamesTitle', function () {
        if (windowSize < large) {
            freeGamesSelector.find('.categories__menu').toggleClass('categories__menu--open');
            $(window).trigger("lookup");
        }
    });


    /* FREE GAMES */
    // $('.jsGamesList').addClass('freeGamesList');

    var $paginator_wrapper = $('.page-num__wrapper');
    // var freeGameItemClass = '.free-games-library__item';

    $('img').on('lazyLoadError', function () {
        console.log($(this));
    });
    var freeGamesOptions = {
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        prevArrow: '<button class="prev pagination__item pagination__item--previous">Prec.</button>',
        nextArrow: '<button class="next pagination__item pagination__item--next">Succ.</button>',
        //centerMode: true,
        easing: 'swing', // see http://api.jquery.com/animate/
        speed: 700,
        dots: true,
        // slide: $freeGameItem,
        appendArrows: $paginator_wrapper,
        appendDots: $paginator_wrapper,
        customPaging: function (slick, index) {
            return '<button class="page-num pagination__item" data-number="' + (index + 1) + '">' + (index + 1) + '</button>';
        }
    };
    var $freeGamesSlider = $('#jsFreeGamesSlider');
    var $hiddenTopPopularGames = $('.jsTopPopularGamesSlide');
    var $gamesPluralConditional = $('#plural-conditional');
    var _reset = 0;
    $('.filter-info').bind("DOMSubtreeModified", function (e) {
        if (!_reset) {
            $paginator_wrapper.html('');
            $freeGamesSlider.slick(freeGamesOptions);
            $hiddenTopPopularGames.addClass('freeGamesList');
            _reset++;
        } else {
            var gameTextHtml = 'Games <span class="filter_results--white">Match Your Preferences</span>';
            if (parseInt($(this).text()) === 1) {
                gameTextHtml = 'Game <span class="filter_results--white">Matches Your Preferences</span>';
            }
            $gamesPluralConditional.html(gameTextHtml);
            _reset = 0;
        }
    });
    var filtersSelect = $('.freeGames__select');

    filtersSelect.on('change', function (e) {
        e.preventDefault();
        $hiddenTopPopularGames.removeClass('freeGamesList');
        if ($freeGamesSlider.hasClass('slick-initialized')) {
            $freeGamesSlider.slick('unslick');
        }
        $freeGamesSlider.html('');
    });

    var loadSlick = 1;
    $freeGamesSlider
        .on('init reInit afterChange', function (event, slick, currentSlide) {
            var slideNumber = (currentSlide ? currentSlide : 0) + 1;
            var range = 3;
            var $pag_button = $('.page-num');
            $pag_button.removeClass('active-friends');
            if (loadSlick === 2) {
                $paginator_wrapper.removeClass('loading');
            }
            loadSlick++;

            $pag_button.each(function () {
                var x = $(this).attr('data-number');
                if (x >= (slideNumber - range) && x <= (slideNumber + range)) {
                    $(this).addClass('active-friends');
                }
            });
        })
        .on('lazyLoadError', function (event, slick, image) {
            $(image).attr('src', '/immagini/giochi-gratis-on-error.jpg');
        });

    $('.filter-clear')
        .on('click', function (e) {
            e.preventDefault();
            $hiddenTopPopularGames.removeClass('freeGamesList');
            if ($freeGamesSlider.hasClass('slick-initialized')) {
                $freeGamesSlider.slick('unslick');
            }
            $freeGamesSlider.html('');
            $('#sort_game').val('0').trigger('change');
        });

    function clickTopListGame(el) {
        var clickedId = el.attr('data-game-id');
        // 'data-game-ref' is the unique attribute used to target the game to click
        $('a[data-game-ref=' + clickedId + ']').trigger('click');
    }

    $('.jsTopListedGame').on('click', function (e) {
        e.preventDefault();
        clickTopListGame($(this));
    });
});