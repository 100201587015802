$(function () {
  var small = 480;
  var medium = 768;
  var large = 1024;
  var windowSize = $(window).width();

  function read_more_mobile() {
    $readMore = $("#ReadMore");
    if ($(window).width() < medium) {
      $readMore.addClass("box-read-more-mobile");
    } else {
      $readMore.removeClass("box-read-more-mobile").readmore("destroy");
    }
  }

  function readMoreButton(readMoreWrapper, collapsedHeight, lessLink) {
    if (lessLink) {
      lessLink = '<a class="btn-read-more arrowup" href="#">Mostra di meno</a>';
    } else {
      lessLink = false;
    }

    readMoreWrapper.readmore({
      speed: 300,
      collapsedHeight: collapsedHeight,
      lessLink: lessLink,
      moreLink:
        '<a class="btn-read-more arrowdown" href="#">Mostra di pi&ugrave;</a>',
      startOpen: false,
    });
  }

  function read_more() {
    read_more_mobile();
    readMoreButton($(".box-read-more-mobile"), 375, false);
  }

  readMoreButton($(".restrictedFlagsReadMore"), 40, true);

  // navigation
  var nav_js = $("#NavJs");
  var nav = nav_js.find(".nav");
  var toggle = nav_js.find(".nav__toggle");
  var background = nav_js.find(".nav__background");
  var body = $("body");

  nav.on("click", ".nav__item span", function (e) {
    e.stopPropagation();
    if (windowSize < large) {
      // close the others
      var parent = $(this).closest(".nav__item");
      nav
        .find(".nav__item")
        .not($(this).closest(".nav__item"))
        .removeClass("nav__item--active")
        .find("ul")
        .slideUp();
      // toggle the one we clicked
      $(this)
        .closest(".nav__item")
        .toggleClass("nav__item--active")
        .find(".nav__submenu")
        .slideToggle();
    }
    return false;
  });

  toggle.on("click", function () {
    if (!$(this).hasClass("nav__toggle--open")) {
      body.addClass("no-scroll");
      var space = $(window).width() - toggle.offset().left - toggle.width();
      toggle.css("right", space);
    } else {
      body.removeClass("no-scroll");
      toggle.css("right", 0);
    }
    $(this).toggleClass("nav__toggle--open");
    nav.toggleClass("nav--open");
    background.stop(true, false).fadeToggle();
  });

  function close_menu_resize() {
    nav.removeClass("nav--open");
    toggle.removeClass("nav__toggle--open").css("right", 0);
    nav.find(".nav__submenu").hide();
    background.hide();
  }

  // FAQ
  var faq = $("#FaqJs");
  faq.find(".faq__item_content").hide();
  faq.on("click", ".faq__item_title", function () {
    $(this).parent().toggleClass("faq__item--open");
    $(this).next(".faq__item_content").stop(true, false).slideToggle();
  });

  //BACK TO TOP
  // browser window scroll (in pixels) after which the "back to top" link is shown
  var offset = 300,
    //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
    offset_opacity = 1200,
    //duration of the top scrolling animation (in ms)
    scroll_top_duration = 700,
    //grab the "back to top" link
    $back_to_top = $(".to-top");

  //hide or show the "back to top" link
  $(window).scroll(function () {
    // add or remove the class to show and hide the back to top button
    if ($(window).scrollTop() > offset) {
      $back_to_top.addClass("to-top--visible");
    } else {
      $back_to_top.removeClass("to-top--visible");
    }

    if ($(this).scrollTop() > offset_opacity) {
      $back_to_top.addClass("to-top--fade");
    }
  });
  // it works together with scrollMeTo and scroll-to functionalities
  // ScrollTo Fallback snippet
  /**
   *  <a href="{fallback_url}" class="jsScrollTo" data-id="{#targetID}">anchor text</a>
   */
  $(".jsScrollTo").each(function () {
    var target = $(this).data("id");
    // console.log(target);
    if ($(target).length) {
      // console.log(target + " exist");
      $(this).addClass("scrollto").attr("href", target);
    }
  });
  // End >> ScrollTo Fallback snippet

  $(".scrollto").on("click", function (e) {
    e.preventDefault();
    var top = $($(this).attr("href")).offset().top;

    $("body, html").animate(
      {
        scrollTop: top,
      },
      scroll_top_duration
    );
  });

  // show preview of table being scrollable
  var responsiveTableClass = ".jsResponsiveTable";
  var responsiveTable = $(responsiveTableClass);

  responsiveTable.wrap("<div class='scrollable-table'></div>");

  var onFindTable = new OnScreen({
    tolerance: 100,
  });

  // Do something when an element enters the viewport
  onFindTable.on("enter", responsiveTableClass, function myCallback(element) {
    $(element).addClass("bounce");
  });

  // Hub box mobile view more
  $(".hubViewMore").on("click", function (e) {
    e.preventDefault();

    // get the container for these hub boxes
    var hubParent = $(this).parent().parent();
    // find the content div for this hub box
    var hubContent = hubParent.find(".hub-block__content");

    if (hubContent.hasClass("active")) {
      // contract all active hub boxes
      hubParent.find(".hub-block__content.active").removeClass("active");
      // update the text on the link
      $(this).html("Mostra di pi&ugrave");
    } else {
      // contract all active hub boxes
      hubParent.find(".hub-block__content.active").removeClass("active");
      // expand this hub box
      hubContent.addClass("active");
      // update the text on the link
      $(this).html("Mostra di meno");
    }
  });

  /** game hubboxes */

  var hubController = {
    btn: $(".hub__load-more"),

    //if btn isnt undefined start the script
    init: function () {
      if (this.btn.length) {
        this.actionElem();
      }
    },
    // show first 10 results
    actionElem: function () {
      var hubItems = $(".hub-block__item").parent();
      $.each(hubItems, function (key, elem) {
        if (key > 9) {
          $(elem).hide();
          $(elem).addClass("hidden");
        }
      });

      //set click listener on show more button
      this.setListener();
    },
    // show elems after click
    showElems: function () {
      var hubItems = $(".hub-block__item").parent(".hidden");
      $.each(hubItems, function (key, elem) {
        if (key < 10 && $(elem).length) {
          $(elem).removeClass("hidden");
          $(elem).show();
        }
      });

      // check for hidden elements and hide the button
      this.hideButton();
    },

    setListener: function () {
      this.btn.click(function (e) {
        e.preventDefault();
        hubController.showElems();
      });
    },

    hideButton: function () {
      var hiddenElLength = $(".hub-block__item").parent(".hidden").length;
      if (!hiddenElLength) {
        this.btn.parent().hide();
      }
    },
  };

  hubController.init();

  // inner toplist
  var slick;
  var slick_slider = $("#Top3Js");

  function start_slick() {
    if (windowSize > medium) {
      if (slick) {
        slick_slider.slick("unslick");
        slick = false;
      }
    } else {
      if (!slick) {
        slick_slider.slick({
          mobileFirst: true,
          centerMode: true,
          infinite: false,
          centerPadding: "40px",
        });
        slick = true;
      }
    }
  }

  start_slick();

  read_more();

  var timeout;
  // this var helps to just apply changes on width resize, not on height (iPhone Safari issue) **
  var originalWindowWidth = $(window).width();

  $(window).resize(function () {
    // limit number of calls
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      if (originalWindowWidth !== $(window).width()) {
        read_more();
        // resize
        originalWindowWidth = $(window).width();
      }
    }, 50);
  });

  // Review Slider
  $(".reviewSlider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".reviewSliderNav",
    lazyLoad: "ondemand",
  });

  $(".reviewSliderNav").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".reviewSlider",
    arrows: false,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    variableWidth: true,
  });

  //games review
  $(".gamesReviewSlider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".gamesReviewSliderNav",
    lazyLoad: "ondemand",
  });

  $(".gamesReviewSliderNav").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".gamesReviewSlider",
    arrows: true,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    variableWidth: true,
  });

  function mobileOnlySlick(sliderSelector) {
    if (windowSize > medium) {
      if (sliderSelector.hasClass("active")) {
        sliderSelector.slick("unslick");
        sliderSelector.removeClass("active");
      }
    } else {
      if (!sliderSelector.hasClass("active")) {
        sliderSelector.slick({
          mobileFirst: true,
          centerMode: true,
          infinite: true,
          centerPadding: "40px",
          arrows: false,
          slidesToShow: 1,
          draggable: true,
          responsive: [
            {
              breakpoint: small,
              settings: {
                slidesToShow: 2,
                centerMode: false,
              },
            },
          ],
        });

        sliderSelector.addClass("active");
      }
    }
  }

  //related boxes slider
  var relatedBoxSlider = $(".relatedBoxSlider");
  mobileOnlySlick(relatedBoxSlider);

  var reasonsSlider = $(".reasonsSlider");
  mobileOnlySlick(reasonsSlider);

  // JackpotTickers

  $(".jsJackpotTicker").each(function () {
    $(this).addClass("js-ticking").simpleTick({
      // decimals: 0,
      currencySpace: true,
      decimal: ",", // decimal point separator
      thousand: ".", // thousands separator
      speed: 1200, // Speed value: if Max/Min speed are set, this value is unused.
      increment: 0.5, // Increment value: if Max/Min increment amounts are set, this value is unused.
      currency: "eur",
      minSpeed: 300, // speed is randomly generated between this Max and Min speed values
      maxSpeed: 900, // ^^^^^
      minIncrement: 0.03, // Increment amount is randomly generated between this Max and Min increment values
      maxIncrement: 0.71, //  ^^^^^^
    });
  });

  // lazy loading
  $(".lazy").unveil(300);

  // resize
  $(window).on("resize", function () {
    windowSize = $(this).outerWidth();
    if (windowSize > large) {
      close_menu_resize();
    }
    start_slick();

    //related boxes slider
    mobileOnlySlick(relatedBoxSlider);
    mobileOnlySlick(reasonsSlider);

    read_more();
  });
});
$(window).on("load", function () {
  windowSize = $(this).outerWidth();

  /* **************************************** */
  /* ******** WINNER FEED ON A MAP ********** */
  /* **************************************** */

  // Define
  var $mapItaly = $("#italy-map");

  function overflowCalc(elPos, elMeasure, wrapperMeasure) {
    var _return = false;
    if (elPos + elMeasure + 10 > wrapperMeasure) {
      _return = true;
    }
    return _return;
  }

  function blurbBuilder(
    pinInfo,
    currentPin,
    xPos,
    yPos,
    rightBlurb,
    bottomBlurb
  ) {
    var extraClass = "";
    var setLeft = 0;
    if (bottomBlurb && rightBlurb) {
      extraClass = "recent-winners-tooltip__wrapper--right-bottom";
    } else if (bottomBlurb) {
      extraClass = "recent-winners-tooltip__wrapper--bottom";
    } else if (rightBlurb) {
      extraClass = "recent-winners-tooltip__wrapper--right";
    }

    if (windowSize > 480) {
      setLeft = xPos;
    }

    $tooltipHTML =
      '<div class="recent-winners-tooltip jsBlurb" data-blurb="' +
      currentPin +
      '" style="top: ' +
      yPos +
      "px; left:  " +
      setLeft +
      'px">' +
      '<div class="recent-winners-tooltip__wrapper ' +
      extraClass +
      '">' +
      '<span class="recent-winners-tooltip__name">' +
      pinInfo.name +
      "</span>" +
      '<dl class="recent-winners-tooltip__menu">' +
      "<dt>Casin\u00f2:</dt>" +
      '<dd class="casino">' +
      pinInfo.casino +
      "</dd>" +
      "<dt>Gioco:</dt>" +
      "<dd> " +
      pinInfo.game +
      "</dd>" +
      "<dt>Data:</dt>" +
      "<dd> " +
      pinInfo.date +
      "</dd>" +
      "<dt>Vincitore:</dt>" +
      "<dd>" +
      pinInfo.name +
      "</dd>" +
      "<dt>Premio:</dt>" +
      "<dd> " +
      pinInfo.amount +
      "</dd>" +
      "</dl>" +
      "<a href='" +
      pinInfo.link +
      "' rel='nofollow' target='_blank' class='btn btn--brand-secondary'>Visita il Sito</a>" +
      "</div>" +
      "</div>";
    return $tooltipHTML;
  }

  function blurbConstructor(pinElement, currentPin, destroyOthers) {
    $("[data-blurb]").remove();
    $("[data-pin]").removeClass("is-active");

    pinElement.addClass("is-visible is-active");
    pos = pinElement[0].getBoundingClientRect();
    $mapItaly = $("#italy-map");
    wrapperPos = $mapItaly[0].getBoundingClientRect();
    // console.log(pos);
    // console.log(pinElement[0].getBoundingClientRect());

    var pinWidth = 270;
    var pinHeight = 340;
    var mapWidth = $mapItaly.width();
    var mapHeight = $mapItaly.height();

    xPos = Math.floor(pos.left - wrapperPos.left);
    yPos = Math.floor(pos.top - wrapperPos.top);

    var overflowX = overflowCalc(xPos, pinWidth, mapWidth);
    var overflowY = overflowCalc(yPos, pinHeight, mapHeight);

    $mapItaly.append(
      blurbBuilder(
        window.pinsInfoObj[currentPin],
        currentPin,
        xPos,
        yPos,
        overflowX,
        overflowY
      )
    );
  }

  var _circle;

  function circleLoop() {
    var _circle = $mapItaly.find("circle");
    var time = 2240;
    var currentPin = 0;
    var removePin = 0;
    var pinIndex = 0;
    var pinsDisplayed = 5;

    // counts the Pins defined in map-italy.js object.
    var numberOfPins = _circle.length;

    // Loop Function to
    function pinpointLoop() {
      if (currentPin < numberOfPins) {
        currentPin = currentPin % numberOfPins;
        // removes the class that makes the pins active
        $("circle[data-pin=" + currentPin + "]").removeClass("is-active");
        pinToRemove = currentPin;
        if (!pinToRemove) {
          pinToRemove = numberOfPins;
        }

        $("[data-blurb=" + pinToRemove + "]").remove();

        currentPin++;

        // Define the pin to remove (removePin):
        // If the pin to remove is 0 or a negative int, it will search the from the last pin
        removePin = currentPin - pinsDisplayed;
        if (removePin < 1) {
          removePin = numberOfPins + removePin;
        }
        // removes the class that makes the pins visible
        $("circle[data-pin=" + removePin + "]").removeClass("is-visible");

        newPin = $("circle[data-pin=" + currentPin + "]");
        blurbConstructor(newPin, currentPin, false);
      } else {
        currentPin = 0;
      }
    }

    // Add data-pin attribute to each Pinpoint.
    $.each(_circle, function () {
      pinIndex++;
      $(this).attr({ "data-pin": pinIndex });

      // Displays the last items, this way the widget starts always showing the pins we define at 'pinsDisplayed'.
      if (numberOfPins - pinsDisplayed < pinIndex) {
        $(this).addClass("is-visible");
      }
    });

    // Defines and starts the loop functionality
    var runLoop = setInterval(pinpointLoop, time);

    //When hover OUT the map, the activity resumes.
    $mapItaly.on("mouseout", function () {
      // time = 300;
      runLoop = setInterval(pinpointLoop, time);
    });

    //When hover over the map, the activity pauses.
    $mapItaly.on("mouseover", function () {
      clearInterval(runLoop);
    });
  }

  var numberOfPins;
  if ($mapItaly.length) {
    $mapItaly.JSMaps({
      map: "italy",
      responsive: true,
      displayAbbreviations: false,
      selectElement: false,
      strokeWidth: 0.5,
      stateClickAction: "none",
      strokeColor: "#fff",
      offStrokeColor: "#444",
      pinSize: 12,
      displayViewBox: false,
      onReady: function () {
        _circle = $mapItaly.find("circle");
        numberOfPins = _circle.length;

        circleLoop();
      },
      onStateClick: function (e) {
        if ($(this)[0]["node"]["nodeName"] === "circle") {
          selectorId = e.id + 1;
          if (selectorId > numberOfPins) {
            selectorId = 1;
          }
          blurbConstructor(
            $("circle[data-pin=" + selectorId + "]"),
            selectorId,
            true
          );
        }
      },
      onStateOver: function () {
        return false;
      },
    });
  }

  /* **************** END ******************* */
  /* ******** WINNER FEED ON A MAP ********** */
  /* **************************************** */
});
