jQuery(function ($) {

    checkCookie();

    function checkCookie() {
        var consent = getCookie("cookies_consent");

        if (consent == null || consent === "") {
            // append the HTML
            $('body').append(
                '<div id="cookies__container" class="cookies__bar" >' +
                '<div class="container" >' +
                '<div class="row" >' +
                '<div class="cookies__bar--cookie_note col-lg-11 col-xs-10">Questo sito utilizza cookies. Rimanendo su questo sito dichiari di accettare e acconsentire all&apos;utilizzo dei cookies in conformit&agrave; con i termini di uso dei cookies espressi in <a target="_blank" href="/cookie-policy/">questo documento</a>.</div>' +
                '<div  id="cookie_accepted" class="cookies__bar__btn--wrapper col-lg-1 col-xs-2"><a href="#" class="cookies__bar__btn btn--brand">OK!</a></div>' +
                '</div>' +
                '</div>' +
                '</div>'
            );
        }
    }

    function setCookie(c_name, value, exdays) {

        var executed_date = new Date();
        executed_date.setDate(executed_date.getDate() + exdays);
        var c_value = encodeURI(value) + ((exdays == null) ? "" : "; expires=" + executed_date.toUTCString());
        document.cookie = c_name + "=" + c_value + "; path=/";

    }


    function getCookie(c_name) {
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x === c_name) {
                return decodeURI(y);
            }
        }
    }

    setCookie("cookies_consent", 1, 60);

    $("#cookie_accepted").find("a").on('click', function (e) {
        e.preventDefault();
        $('#cookies__container').slideUp('slow');
    });
});